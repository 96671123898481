.tool-item{
    padding: 0.1em 1em;
    border-radius: 10px;
    margin: 1em 0;
    /*border: 1px solid;*/
    box-shadow: 0 0 5px 0px lightgray ;

    /*margin: auto;*/

}
.tool-item *{
    margin: 0.5em 0;
}
.tool-title{
    padding: 0;
    display:flex;
    flex-wrap: wrap;
    font-size: 1.5em;
    /*margin:0.5em 0*/
}
.tool-title *{
    margin: 0 1em 0 0;
    background: white;

}
.tool-name{
    text-decoration: none;
    /*text-align: center;*/
}
.tool-type{
    /*display: block;*/
    white-space: nowrap
    /*text-align: center;*/
}
div.tool-type *{
    margin:0 0.2em;
    /*text-align: center;*/
}

.tool-icon {
    display: inline;
    /*font-size: 1.5em;*/
}
.tool-description{
    /*text-align: center;*/
}

.tool-limit{
    /*text-align: center;*/
}

.header-line{
    color: gray;
    /*margin:0.5em 0;*/
}

h2{
    font-weight: normal;
    font-size: 24px;
    line-height: 1.5em;
}